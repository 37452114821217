import {NgModule} from "@angular/core";
import {Route, RouterModule} from "@angular/router";
import {OnlyGuestGuard} from "./guards/only-guest.guard";
import {
  AuthComponent,
  ForgotPassComponent,
  ResetPassComponent,
  SignInComponent,
  SignUpComponent
} from "./containers";
import {SignUpNewComponent} from "./containers/sign-up-new/sign-up-new.component";

const routes: Route[] = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [OnlyGuestGuard],
    children: [{
      path: 'sign-in',
      component: SignInComponent
    }, {
      path: 'activate/:userId/:token',
      component: SignInComponent
    }, {
      path: 'sign-up',
      component: SignUpNewComponent
    }, {
      path: 'sign-up-affiliate',
      component: SignUpNewComponent
    },
    {
      path: 'login_onetime/:uid/:onetime_pass',
      component: SignInComponent
    },
    {
      path: 'login_onetime_admin/:uid/:onetime_pass',
      component: SignInComponent
    },
    {
      path: 'forgot-pass',
      component: ForgotPassComponent
    }, {
      path: 'reset-pass/:user_id/:token',
      component: ResetPassComponent
    },
    {
      path: 'socialite_provider/google/callback',
      component: SignInComponent
    }, {
      path: 'socialite_provider/linkedin/callback',
      component: SignInComponent
    }, {
      path: 'socialite_provider/github/callback',
      component: SignInComponent
    }, {
      path: '',
      redirectTo: 'sign-in',
      pathMatch: 'full'
    }]
  }, {
    path: 'auth',
    redirectTo: '',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [OnlyGuestGuard]
})
export class AuthRoutingModule {

}
