import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AddPayment} from "../../core/models/add-payment.model";
import {Observable} from "rxjs";
import {IUserPlan, User} from "../../core/models/user.model";
import {Invoice} from "../models/invoice.model";
import DevExpress from "devextreme";
import data = DevExpress.data;
import {UserPayoutMethodItem} from "../models/user-payout-method-item.model";
import {MailSettings} from '../account.facade';

@Injectable()
export class AccountApi {
	/**
	 * Constructor for AccountApi service
	 * @param {HttpClient} httpClient
	 */
	constructor(private httpClient: HttpClient) { }
	
	/**
	 * Change pass
	 * @param {string} old_password
	 * @param {string} password
	 * @param {string} password_confirmation
	 * @return {Observable<{ status: string; message: string }>}
	 */
	
	changePassword(old_password: string, password: string, password_confirmation: string): Observable<{ status: string; message: string }> {
		const postData = {
			old_password,
			password,
			password_confirmation
		}
		return this.httpClient.post<{ status: string; message: string }>('monetizer_api/api/user/change_password', postData)
	}
	
	updateUser(name: string): Observable<{ data: { user: User} }> {
		const postData = {
			name
		};
		return this.httpClient.post<{data: {user: User}}>('monetizer_api/api/user/profile/set', postData);
	}
	
	updateLanguage(language: string): Observable<any> {
		const postData = {
			language
		};
		return this.httpClient.post<any>('monetizer_api/api/user/language/set', postData);
	}
	
	deleteUser(): Observable<{ success: boolean }> {
		return this.httpClient.delete<{ success: boolean }>('monetizer_api/api/user/delete');
	}
	
	/**
	 * @return {Observable<{ data: Invoice[] }>}
	 */
	getInvoices(): Observable<{ data: Invoice[] }> {
		return this.httpClient.get<{data: Invoice[]}>('monetizer_api/api/payments/get_invoices');
	}
	
	getAllPlans(): Observable<{data: IUserPlan[]}> {
		return this.httpClient.get<{data: any}>('monetizer_api/api/plans/get_plans');
	}
	
	getUserPayoutMethods(): Observable<UserPayoutMethodItem[]> {
		return this.httpClient.get<UserPayoutMethodItem[]>('monetizer_api/api/affiliate/payout/methods');
	}

	editUserPayoutMethods(payoutMethodsChanges: any): Observable<any> {
		const postData = {
			payout_methods: payoutMethodsChanges
		};
		return this.httpClient.post<any>('monetizer_api/api/user/payout_methods/set', postData);
	}
	
	confirmPayoutChanges(token: string): Observable<any> {
		const postData = {
			confirm_token: token
		};
		return this.httpClient.post<any>('monetizer_api/api/user/payout_methods/confirm', postData);
	}
	
	setConsentData(cookieData: string): Observable<any> {
		const postData = {
			consent:  cookieData
		};
		return this.httpClient.post('monetizer_api/api/user/consent_updated', postData);
	}
	
	getUserInfo() : Observable<any> {
		return this.httpClient.get<any>('monetizer_api/api/user/get');
	}
	
	getMailSettings(): Observable<{data: MailSettings}> {
		return this.httpClient.get<{data: MailSettings}>('monetizer_api/api/user/mail_settings/get');
	}
	
	updateMailSettings(data: MailSettings): Observable<{ success: boolean; url?:string }> {
		return this.httpClient.post<{ success: boolean; url?:string }>('monetizer_api/api/user/mail_settings/set', data)
	}
}
