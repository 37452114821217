import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter
} from "@angular/material-moment-adapter";

import * as _moment from 'moment';
import {TranslateService} from "@ngx-translate/core";
import 'moment/locale/ja';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ur';
import {MatDatepickerIntl} from '@angular/material/datepicker';

const moment = _moment;

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: 'en-US'},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}]
})
export class DatePickerComponent implements OnInit {
	
	@Input() startDate: string;
	@Input() endDate: string;
	
	@Input() minDate: string | Date;
	@Input() maxDate: string | Date;
	
	selectDateGroup!: FormGroup;
	
	@Output() update: EventEmitter<any> = new EventEmitter<any>();
	
	constructor(private _adapter: DateAdapter<any>,
	            private _intl: MatDatepickerIntl,
	            @Inject(MAT_DATE_LOCALE) private _locale: string,
	            protected translateService: TranslateService) {
		const today = new Date();
		const rangeValue = 7;
		this.minDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
		this.maxDate = new Date();
		this.startDate = moment(today.setDate(today.getDate() - rangeValue)).format('YYYY-MM-DD');
		this.endDate = moment().format('YYYY-MM-DD');
		
		this.setTranslation(this.translateService.currentLang);
		this.translateService.onLangChange.subscribe(langEvent => {
			this.setTranslation(langEvent.lang)
		})
	}
	
	setTranslation(lang: string) {
		switch (lang) {
			case 'en' : this._locale = 'en-US';
				break;
			case 'pt' : this._locale = 'pt-PT';
				break;
			case 'ja' : this._locale = 'ja-JP';
				break;
			case 'es' : this._locale = 'es-ES';
				break;
			case 'fr' : this._locale = 'fr-FR';
				break;
			case 'ur' : this._locale = 'ur-PK';
				break;
				
			default : this._locale = 'en-US';
		}
		this._adapter.setLocale(this._locale);
		this.updateCloseButtonLabel();
	}
	
	updateCloseButtonLabel() {
		this.translateService.get('Close calendar').subscribe(translated => {
			this._intl.closeCalendarLabel = translated;
			this._intl.changes.next();
		});
	}
	
	ngOnInit(): void {
		this.selectDateGroup = new FormGroup({
			start: new FormControl(moment(this.startDate)), end: new FormControl(moment(this.endDate))
		});
	}
	
	onChangeRange() {
		if (!this.selectDateGroup.value.end) {
			return false;
		}
		this.startDate = this.selectDateGroup.value.start.format('YYYY-MM-DD');
		this.endDate = this.selectDateGroup.value.end.format('YYYY-MM-DD');
		this.updateData();
		return false;
	}
	
	updateData() {
		this.update.emit({
			start: this.startDate, end: this.endDate
		});
	}
	
}
