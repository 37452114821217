import {ApplicationRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  fadeInOnEnterAnimation,
  jackInTheBoxOnEnterAnimation,
  zoomInDownOnEnterAnimation,
  zoomOutOnLeaveAnimation
} from "angular-animations";
import {AuthFacade} from "../../auth.facade";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'di-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    zoomInDownOnEnterAnimation({duration: 500, delay: 100}),
    zoomOutOnLeaveAnimation({duration: 300}),
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
    fadeInOnEnterAnimation()
  ]
})
export class AuthComponent implements OnInit, OnDestroy {

  /**
   * Subject to destroy all subscriptions
   * @type {Subject<void>}
   */
  destroy$: Subject<void>;
  
  
  public SITE_ID_NG = environment.recaptchaKey;
  
  logoUrl: string = environment.logoUrl;
  
  themes = ["dark", "light"]; // <- list all themes in this array
  theme = new BehaviorSubject("light"); // <- initial theme
  
  regSource: string = 'main';
  /**
   * Constructor for AuthComponent
   * @type {AuthFacade} authFacade
   * @type {ActivatedRoute} activatedRoute
   */
  constructor(private authFacade: AuthFacade,
              private activatedRoute: ActivatedRoute,
              private ref: ApplicationRef,
              private router: Router) {
    this.destroy$ = new Subject<void>();
    const darkModeOn =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    
    // If dark mode is enabled then directly switch to the dark-theme
    if(darkModeOn){
      this.theme.next("dark");
    }
    
    // Watch for changes of the preference
    window.matchMedia("(prefers-color-scheme: dark)").addListener(e => {
      const turnOn = e.matches;
      this.theme.next(turnOn ? "dark" : "light");
      
      // Trigger refresh of UI
      this.ref.tick();
    });
    
    if(this.router.url.includes('sign-up-affiliate')) {
      this.regSource = 'affiliate';
    }
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        this.saveAffiliateIdFromRoute(params);
      })
  }

  /**
   * Check route params and save affiliateID && friendID to localStorage if they are exist
   * @param {ParamMap} params
   * @private
   */
  private saveAffiliateIdFromRoute(params: ParamMap) {
    if (params.has('aff')) {
      const affiliateID = (params.get('aff')) || '';
      localStorage.setItem('affiliateID', parseInt(affiliateID) > 0 ? parseInt(affiliateID).toString() : '')
    }
    
    if (params.has('promo_aff')) {
      const promoAffiliate = (params.get('promo_aff')) || '';
      localStorage.setItem('promoAffiliate', promoAffiliate)
    }
    
    if (params.has('friendID')) {
      const friendID = params.get('friendID') || '';
      localStorage.setItem('friendID', friendID)
    }
  }

  /**
   * Call on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
