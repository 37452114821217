import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AuthFacade} from "../../auth.facade";
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GaService} from "../../../core/services/ga.service";
import {RegisterFormModel} from "../../models/register-form.model";
import {FingerprintjsProAngularService} from "@fingerprintjs/fingerprintjs-pro-angular";
import {MustMatch} from "../sign-up-new/sign-up-new.component";




declare let gtag:Function;
@Component({
  selector: 'monetizer-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../sign-in/sign-in.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignUpComponent implements OnInit {
  
  stepsArray: number[];
  formData!: Partial<RegisterFormModel>;
  
  currentStep$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  
  isCurrentFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  acceptTerms: FormControl =  new FormControl(false, Validators.requiredTrue)
  
  stepsCount = 4;
  isLoading!: Observable<boolean>;
  
  affiliateID: string|null = null
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  /**
   * Register data form
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Getter for form name control
   * @type {AbstractControl}
   */
  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Getter for form password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form.get('passwordConfirm');
  }

  /**
   * Constructor for SignUpComponent
   * @type {AuthFacade} authFacade
   */
  constructor(private authFacade: AuthFacade, private gaService: GaService,
              // private fingerprintService: FingerprintjsProAngularService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.minLength(2)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(1000)
      ]),
      passwordConfirm: new FormControl('', [
      
      ])
    }, {validators: MustMatch('password', 'passwordConfirm')})
    
    this.stepsArray = Array.from({length: this.stepsCount}, (_, i) => i + 1)
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.affiliateID = (localStorage.getItem('affiliateID')) ? localStorage.getItem('affiliateID') : null;
    this.isLoading = this.authFacade.getLoadingState();
    this.getGtagParams();
  }
  
  getGtagParams() {
    (window as any).clsid = {
      client_id: '',
      session_id: ''
    };
    try {
      gtag('get', environment.measurementId, 'client_id', function(r: any) {
        (window as any).clsid.client_id = r
      });
    } catch (e) {
      console.warn(e)
    }
    try {
      gtag('get', environment.measurementId, 'session_id', function(r: any) {
        (window as any).clsid.session_id = r
      });
    } catch (e) {
      console.warn(e)
    }
  }
  /**
   * Call action to register user
   * @return {void}
   */
  registerUser(captcha:string): void {
    if (this.form.valid){
      const ga_cid = this.gaService.getGaCid();
      this.authFacade.registerUser({...this.form.value, recaptcha: captcha, gaCid: ga_cid});
    }
  }
  
  goToThirdParty(part: string) {
    this.authFacade.goToThirdParty(part).subscribe((url) => {
      window.location.href = url;
    })
  }
  
  nextStep() {
    let currStep =  this.currentStep$.value;
    this.currentStep$.next(++currStep);
  }
  
  prevStep() {
    let currStep =  this.currentStep$.value;
    this.currentStep$.next(--currStep);
  }
  
  updateStepForm($event: {formData: Partial<RegisterFormModel>, valid: boolean}) {
    this.formData = {...this.formData, ...$event.formData}
    // console.log(this.formData);
    this.isCurrentFormValid$.next($event.valid);
  }
  
  async completeRegister(captcha: string): Promise<void> {
    if ( this.isCurrentFormValid$.value) {
      const formData = {...this.formData} as RegisterFormModel
      const ga_cid = this.gaService.getGaCid();
      const g_session_id = (window as any).clsid.session_id;
      let visitor_id;
      try {
        // const data = await this.fingerprintService.getVisitorData();
        // visitor_id = data.visitorId;
      } catch (e) {
      } finally {
      }
      this.authFacade.registerUser({...formData, recaptcha: captcha, gaCid: ga_cid, visitorId: visitor_id, g_session_id})
    }
    
  }

}
