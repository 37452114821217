import {Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router, ActivatedRoute } from "@angular/router";
import {AuthFacade} from "../../auth.facade";
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {environment} from "../../../../environments/environment";
import {GaService} from "../../../core/services/ga.service";
import {FingerprintjsProAngularService} from "@fingerprintjs/fingerprintjs-pro-angular";
import {RecaptchaComponent} from "ng-recaptcha";
import {CookieService} from "../../../core/services/cookie.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../core/store/app.reducer";
import {getLanguage} from "../../../core/store/view/view.selectors";

@Component({
  selector: 'dataimpulse-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignInComponent implements OnInit, OnDestroy {
  
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent
  
  isLoading!: Observable<boolean>;
	
	isShowPass: boolean = false;
  
  message!: string;
  typeOfMessage!: string;
  /**
   * Login for group
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Id of current user
   * @type {string}
   */
  userId!: string;

  /**
   * Token of current user
   * @type {string}
   */
  token!: string;

  /**
   * Getter for email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Getter for password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  /**
   * Subject for unsubscribing
   * @type {Subject<void>}
   */
  destroy$: Subject<void>;
  
  env = environment;
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  
  affiliateID: string|null = null;
  
  promoAffiliate: string|null = null;
  
  language: string = 'en';
  /**
   * Constructor for SignInComponent
   * @param {AuthFacade} authFacade
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {MatDialog} dialog
   * @param gaService
   * @param fingerprintService
   * @param cookieService
   * @param store
   */
  constructor(private router: Router,
              private authFacade: AuthFacade,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private gaService: GaService,
              private fingerprintService: FingerprintjsProAngularService,
              private cookieService: CookieService,
              private store: Store<AppState>) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000)
      ])
    });
  
    this.store.select(getLanguage).subscribe(lng => {
      this.language = lng ?? 'en';
    })
    
    this.destroy$ = new Subject<void>();
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.affiliateID = (localStorage.getItem('affiliateID')) ?
        localStorage.getItem('affiliateID') : this.cookieService.getCookie('aff_id');
    this.promoAffiliate = (localStorage.getItem('promoAffiliate')) ?
        localStorage.getItem('promoAffiliate') : this.cookieService.getCookie('promo_aff');
    
    this.activatedRoute.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      if (params.token && params.userId) {
        this.userId = params.userId;
        this.token = params.token;

        this.verifyUser(this.userId, this.token);
      } else if (params.uid && params.onetime_pass) {
        this.loginByOnetimePass(params.uid, params.onetime_pass);
      }
    })
  
    if (this.router.url.indexOf('/socialite_provider') === 0) {
      this.loginByThirdParty().finally();
    }
    this.isLoading = this.authFacade.getLoadingState();
  }
  
  async loginByThirdParty() {
    let code = '';
    let scope = '';
    let authuser = '';
    let session_state = '';
    let prompt = '';
  
    let provider = 'google';
    if(this.router.url.includes('/google/')) {
      provider = 'google';
    } else if(this.router.url.includes('/facebook/')) {
      provider = 'facebook';
    } else if(this.router.url.includes('/linkedin/')) {
      provider = 'linkedin';
    } else if(this.router.url.includes('/github/')) {
      provider = 'github';
    }
  
    let visitor_id = '';
    try {
      const data = await this.fingerprintService.getVisitorData();
      visitor_id = data.visitorId;
    } catch (e) {
    } finally {
    }
  
    this.activatedRoute.queryParams
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      code = params['code'];
      scope = params['scope'] ?? null;
      authuser = params['authuser'] ?? null;
      session_state = params['session_state'] ?? null;
      prompt = params['prompt'] ?? null;
    
      let affiliate_id = this.affiliateID !== null ? this.affiliateID : '';
      let promo_affiliate = this.promoAffiliate !== null ? this.promoAffiliate : '';
      const ga_cid = this.gaService.getGaCid();
      const g_session_id = (window as any).clsid?.session_id;
      const gclid = this.cookieService.getCookie('gclid');
      let utm_source = this.cookieService.getCookie('utm_source');
      let utm_campaign = this.cookieService.getCookie('utm_campaign');
      let utm_term = this.cookieService.getCookie('utm_term');
      let utm_content = this.cookieService.getCookie('utm_content');
      let utm_medium = this.cookieService.getCookie('utm_medium');
    
      if(ga_cid &&
          (gclid === null || gclid === undefined || gclid === '')
          && (utm_source === null || utm_source === undefined || utm_source === '')) {
        utm_source = 'none';
        utm_campaign = 'none';
        utm_term = 'none';
        utm_content = 'none';
        utm_medium = 'none';
        if (document.referrer && document.referrer.length > 1){
          let r = document.referrer;
          r = r.replace(/(https?:\/\/)?(www.)?/i, '');
          if (r.indexOf('/') !== -1) {
            r = r.split('/')[0];
          }
        
          if(r.indexOf('google.') !== -1) {
            r = 'google';
          }
          if(r.indexOf('bing.') !== -1) {
            r = 'bing';
          }
          if(r.indexOf('yahoo.') !== -1) {
            r = 'yahoo';
          }
        
          utm_source = r;
          utm_medium = 'referral';
          if(utm_source === 'google' || utm_source === 'bing' || utm_source === 'yahoo') {
            utm_medium = 'organic';
          }
        
        } else {
          utm_source = 'direct';
        }
      }
    
      const fbp = this.cookieService.getCookie('_fbp');
      const fbc = this.cookieService.getCookie('_fbc');
    
      const reg_source = this.cookieService.getCookie('reg_source') ?? 'main';
      
      const payload = {
        provider,
      
        code,
        scope,
        authuser,
        session_state,
        prompt,
      
        affiliate_id,
        promo_affiliate,
        gaCid: ga_cid,
        visitorId: visitor_id,
        g_session_id,
        utm_params: {
          gclid: gclid,
          utm_source: utm_source,
          utm_campaign: utm_campaign,
          utm_term: utm_term,
          utm_content: utm_content,
          utm_medium: utm_medium,
          g_session_id: g_session_id,
          fbp: fbp,
          fbc: fbc,
        },
        language: this.language,
        use_case: (reg_source === 'affiliate' ? 'Affiliate Program' : undefined)
      }
    
      this.authFacade.loginByThirdParty(provider, payload)
    });
  }
  
  /**
   * Change page handler
   * @return {void}
   */
  goToForgot(): void {
    this.router.navigate(['/forgot-pass']);
  }

  /**
   * Change page handler
   * @return {void}
   */
  goToSignUp(): void {
    this.router.navigate(['/sign-up']);
  }
  
  goToThirdParty(provider: string) {
    this.authFacade.goToThirdParty(provider).subscribe((url) => {
      window.location.href = url;
    })
  }

  /**
   * Call action to login user
   * @return {void}
   */
  async loginUser(captcha:string): Promise<void> {
    if (this.form.valid) {
      let visitor_id;
      try {
        const data = await this.fingerprintService.getVisitorData();
        visitor_id = data.visitorId;
      } catch (e) {
      } finally {
      }

      this.authFacade.loginUser({...this.form.value, recaptcha: captcha, visitor_id})
      this.captchaRef?.reset();
    }
  }
  /**
   * Request for verify user
   * @param {string} userId 
   * @param {string} token
   * @return {void} 
   */
  verifyUser(userId: string, token: string): void {
    this.authFacade.verifyUser(userId, token)
  
  }
  
  loginByOnetimePass(uid: string, onetime_pass: string) {
    this.authFacade.loginByOnetimePass(uid, onetime_pass);
  }

  /**
   * Call on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }
	
	showPass(e: any) {
		this.isShowPass = !this.isShowPass;
	}

}
