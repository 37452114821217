<section class="left-menu">
	<div [@enter] class="nk-sidebar nk-sidebar-fixed is-light" [class.shown]="isShown" data-content="sidebarMenu">
		<div class="nk-sidebar-element nk-sidebar-head">
			<div class="nk-sidebar-brand">
				<a routerLink="dashboard" (click)="menuItemClickHandler()" class="logo-link nk-sidebar-logo">
					<img class="logo-dark logo-img" src="{{env.logoUrl}}" alt="logo-dark">
				</a>
			</div>
		</div>
		<div class="nk-sidebar-element">
			<div class="nk-sidebar-content">
				<div class="nk-sidebar-menu">
					<ul class="nk-menu">
						<li [class.active]="router.url.includes('dashboard')" class="nk-menu-item current-page">
							<a routerLink="dashboard" (click)="menuItemClickHandler()" class="nk-menu-link" title="Dashboard">
								<i class="icon-dashboard mr-2"></i>
								<span class="nk-menu-text">{{'Dashboard' | translate}}</span></a>
						</li>

						<li class="nk-menu-item current-page">
							<div (click)="menuItemClickHandler()" class="nk-menu-link" title="Plans">
								<i class="icon-products mr-2"></i>
								<span class="nk-menu-text">{{'Products' | translate}}</span></div>
							<ul class="nk-submenu">
								<li *ngFor="let productItem of productsMenuItems" class="nk-menu-item" [class.active]="router.url.includes('products/'+productItem.id)">
									<a routerLink="products/{{productItem.id}}" class="nk-menu-link" (click)="menuItemClickHandler()">
										<i class="mr-2" [ngClass]="{'icon-residential': productItem.pool_type == 'residental', 'icon-datacenter': productItem.pool_type == 'datacenter', 'icon-mobile': productItem.pool_type == 'mobile'}">
										</i>{{productItem.label}}</a>
								</li>
								<li class="nk-menu-item">
									<a routerLink="plans/create-new" class="nk-menu-link">+ {{'Add new plan' | translate}}</a>
								</li>
							</ul>
						</li>

						<li [class.active]="router.url.includes('plans')" class="nk-menu-item current-page">

							<a routerLink="plans" (click)="menuItemClickHandler()" class="nk-menu-link" title="Plans">
								<i class="icon-plans mr-2"></i>
								<span class="nk-menu-text">{{'Plans' | translate}}</span></a>
						</li>

						<li class="nk-menu-item current-page">
							<div (click)="menuItemClickHandler()" class="nk-menu-link" title="Reseller Program">
								<i class="dx-icon-cellproperties mr-2"></i>
								<span class="nk-menu-text">{{'Reseller Program' | translate}}</span></div>
							<ul class="nk-submenu">
								<li class="nk-menu-item" [class.active]="router.url.includes('reseller/api-management')">
									<a routerLink="reseller/api-management" class="nk-menu-link" (click)="menuItemClickHandler()">{{'API Management' | translate}}</a>
								</li>
								<li class="nk-menu-item" [class.active]="router.url.includes('reseller/deposit')">
									<a routerLink="reseller/deposit" class="nk-menu-link" (click)="menuItemClickHandler()">{{'Deposit' | translate}}</a>
								</li>
							</ul>
						</li>

						<li [class.active]="router.url.includes('affiliate')" class="nk-menu-item current-page">

							<a routerLink="affiliate" (click)="menuItemClickHandler()" class="nk-menu-link" title="Affiliate Program">
								<i class="icon-affiliate mr-2"></i>
								<span class="nk-menu-text">{{'Affiliate Program' | translate}}</span></a>
						</li>

						<li [class.active]="router.url.includes('docs')" class="nk-menu-item current-page">

							<a href="https://docs.dataimpulse.com/" target="_blank" class="nk-menu-link" title="Docs">
								<i class="icon-docs mr-2"></i>
								<span class="nk-menu-text">{{'Docs' | translate}}</span></a>
						</li>

						<li [class.active]="router.url.includes('locations')"  class="nk-menu-item current-page">
							<div (click)="menuItemClickHandler()" class="nk-menu-link" title="Proxy Locations">
								<i class="dx-icon-cellproperties mr-2"></i>
								<span class="nk-menu-text">{{'Proxy Locations' | translate}}</span></div>
							<ul class="nk-submenu">
								<li class="nk-menu-item" [class.active]="router.url.includes('locations?pool=residential')">
									<a routerLink="locations" [queryParams]="{pool: 'residential'}" class="nk-menu-link" (click)="menuItemClickHandler()">{{'Residential' | translate}}</a>
								</li>
								<li class="nk-menu-item" [class.active]="router.url.includes('locations?pool=datacenter')">
									<a routerLink="locations" [queryParams]="{pool: 'datacenter'}" class="nk-menu-link" (click)="menuItemClickHandler()">{{'Datacenter' | translate}}</a>
								</li>
								<li class="nk-menu-item" [class.active]="router.url.includes('locations?pool=mobile')">
									<a routerLink="locations" [queryParams]="{pool: 'mobile'}" class="nk-menu-link" (click)="menuItemClickHandler()">{{'Mobile' | translate}}</a>
								</li>
							</ul>
						</li>
						
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
