import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/app.reducer";
import {getLanguage} from "../../../store/view/view.selectors";
import {setLanguage} from "../../../store/view/view.actions";
import {isUserAuthenticated, selectUser} from "../../../store/user/user.selector";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  
  @Input() size: string = 'full'
  
  isDropdownOpen: boolean = false;
  
  currentLanguage?: {key: string, value: string} = {key: 'en', value: 'English'};
  
  languagesList: {key: string, value: string}[] = [
    {key: 'en', value: 'English'},
    {key: 'fr', value: 'French'},
    {key: 'ja', value: '日本語 (Japanese)'},
    {key: 'pt', value: 'Portuguese'},
    {key: 'es', value: 'Spanish'},
    {key: 'ur', value: '(Urdu) اردو'},
  ];
  
  authenticated: boolean = false;
  
  constructor(private store: Store<AppState>) {
    this.store.select(isUserAuthenticated).subscribe(auth => {
      this.authenticated = auth;
    })
  
    this.store.select(getLanguage).subscribe(lng => {
      if(lng !== undefined && lng !== this.currentLanguage?.key) {
        this.changeLanguage(lng);
      }
    })
  }
  
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  changeLanguage(langCode: string): void {
    this.currentLanguage = this.languagesList.find( l => l.key === langCode);
    this.store.dispatch(setLanguage({language: langCode}));
  }
  
  closeDropdown(): void {
    this.isDropdownOpen = false;
  }
  
  ngOnInit(): void {
  }

}
