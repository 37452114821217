import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {User} from "../../core/models/user.model";
import {RegisterFormModel} from "../models/register-form.model";

@Injectable()
export class AuthApi {

  /**
   * Constructor for AuthApi service
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) { }

  /**
   * User login
   * @param {string} email
   * @param {string} password
   * @param {string} recaptcha
   * @param visitor_id
   * @return {Observable<{data: {token: string, user: User}}>}
   */
  userLogin(email: string, password: string, recaptcha: string, visitor_id: string): Observable<{data: {token: string, user: User}}> {
    const postData = {
      email,
      password,
      'g-recaptcha-response': recaptcha,
      visitor_id
    };
    return this.httpClient.post<{data: {token: string, user: User}}>('monetizer_api/api/auth/login', postData);
  }
  
  /**
   * User login onetime
   * @param {string} user_id
   * @param {string} onetime_password
   * @return {Observable<{data: {token: string, user: User}}>}
   */
  
  loginOneTime(user_id: string, onetime_password: string): Observable<{data: {token: string, user: User}}> {
    const postData = {
      user_id,
      onetime_password
    }
  
    return this.httpClient.post<{data: {token: string, user: User} }>('monetizer_api/api/auth/login_by_onetime', postData);
  }

  /**
   * Register new user by login and pass
   * @param data
   * @param referrerParams
   * @return {Observable<{data: {token: string, user: User}}>}
   */
  userRegister(data: RegisterFormModel, referrerParams?: any): Observable<{data: {token: string, user_id: number, user: User}}> {
  
    let name =  data.firstName +' ' + data.lastName;
    if (data.name) {
      name = data.name;
    }
    
    const postData = {
      email: data.email.toLowerCase(),
      name,
      password: data.password,
      password_confirmation: data.passwordConfirm,
      messenger_type: data.messenger_type,
      messenger_account: data.messenger_account,
      use_case: data.use_case,
      company_name: data.company ?? undefined,
      phone: data.phone ?? undefined,
      affiliate_id: data.affiliate_id,
      promo_affiliate: data.promo_affiliate,
      ga_cid: data.gaCid,
      g_session_id: data.g_session_id,
      visitor_id: data.visitorId,
      language: data.language,
      'g-recaptcha-response': data.recaptcha,
      'referrer_params' : JSON.stringify(referrerParams),
      utm_params: JSON.stringify(data.utm_params)
    };

    return this.httpClient.post<{data: {token: string, user_id: number, user: User}}>('monetizer_api/api/auth/register', postData);
  }

  /**
   * Send email for getting instructions to reset password
   * @param {string} email
   * @param {string} recaptcha
   * @return {Observable<{data: string}}>}
   */
  sendEmailForReset(email: string, recaptcha: string): Observable<{data: string}> {
    const postData = {
      email,
      'g-recaptcha-response': recaptcha,
    };

    return this.httpClient.post<{data: string}>('monetizer_api/api/user/forgot_password', postData);
  }

  /**
   * Send updated user password
   * @param user_id
   * @param token 
   * @param password
   * @param {string} recaptcha
   * @returns {Observable<{data: any}>}
   */
  sendNewPass(user_id: number, token: string, password: string, recaptcha: string): Observable<{data: any}> {
    const postData = {
      user_id,
      token,
      password,
      'g-recaptcha-response': recaptcha
    };
    return this.httpClient.post<{data: any}>('monetizer_api/api/user/reset_password', postData);
  }

  /**
   * Request for verify user
   * @param {userId: string} user_id
   * @param {token: string} activation_code
   * @return {Observable<any>}
   */
  verifyUser(user_id: string, activation_code: string): Observable<any> {
    const postData = {
      user_id,
      activation_code
    }
  
    return this.httpClient.post<any>('monetizer_api/api/user/activate', postData);
  }
  
  
  goToThirdParty(provider: string): Observable<{ target_url: string  }> {
    return this.httpClient.get<{ target_url: string }>(`monetizer_api/api/auth/get_provider_target_url/${provider}`);
  }
  
  /**
   * User login by Third Party
   * @return {Observable<{data: {token: string, user: User}}>}
   */
  userLoginByThirdParty(payload: any): Observable<{data: {token: string, user: User}}> {
    const postData = {
      code: payload.code,
      scope: payload.scope,
      authuser: payload.authuser,
      session_state: payload.session_state,
      prompt: payload.prompt,
      affiliate_id: payload.affiliate_id,
      promo_affiliate: payload.promo_affiliate,
      ga_cid: payload.gaCid,
      g_session_id: payload.g_session_id,
      visitor_id: payload.visitorId,
      referrer_params: JSON.stringify(payload.referrer_params),
      utm_params: JSON.stringify(payload.utm_params),
      language: payload.language,
      use_case: payload.use_case
    };
    
    return this.httpClient.post<{data: {token: string, user: User}}>(`monetizer_api/api/auth/socialite_provider/${payload.provider}/callback`, postData);
  }

}
