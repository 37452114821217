import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ResellerApi} from "./api/reseller.api";
import {ISubUser, IUserBalanceSubscription, IUserCustomPrice} from "../../core/models/user.model";
import {CartItem} from "../../core/models/cart-item.model";

@Injectable()
export class ResellerFacade {
	
	/**
	 * Constructor for ResellerFacade
	 * @param {ResellerApi} api
	 */
	constructor(private api: ResellerApi) {
	}
	
	getBalanceInfo(): Observable<{balance: number, price_per_traffic: number, custom_price_data: IUserCustomPrice[], subscription: IUserBalanceSubscription}> {
		return this.api.getBalanceInfo()
		.pipe(
			map(response => response)
		)
	}
	
	getSubUsersList(options: any): Promise<any> {
		return this.api.getSubUsersList(options)
		.toPromise()
		.then(response => {
			return {
				data: response.data,
				totalCount: response.totalCount
			}
		})
	}
	
	getSubUserUsageDetails(options: any): Promise<any> {
		return this.api.getSubUserUsageDetails(options)
		.toPromise()
		.then(response => {
			const data = response.data.length ? response.data.map(item => {
				const date: Date = new Date(item.datetime.replace(/-/g,'/'));
				return {
					...item,
					datetime: new Intl.DateTimeFormat('en-US', {
						day: "numeric",
						month: "numeric",
						year: "numeric",
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric'
					}).format(date)
				}
			}) : response.data;
			return {
				data: data,
				totalCount: response.totalCount
			}
		})
	}
	
	getSubUsersListMath(): Observable<ISubUser[]> {
		return this.api.getSubUsersListMath()
		.pipe(
			map(response => response.subusers)
		)
	}
	
	getApiToken(password: string): Observable<{token: string, message: string, success: boolean}> {
		return this.api.getApiToken(password)
		.pipe(
			map(response => response)
		)
	}
	
	getLastApiToken(): Observable<string> {
		return this.api.getLastApiToken()
		.pipe(
			map(response => response.token)
		)
	}
	
	addSubUserTraffic(subUserId: number, traffic: number): Observable<{message: string, success: boolean}> {
		return this.api.addSubUserTraffic(subUserId, traffic)
		.pipe(
			map(response => response)
		)
	}
	
	npBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		return this.api.npBalanceCheckout(amount);
	}
	
	cmBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		return this.api.cmBalanceCheckout(amount);
	}
	
	paddleBalanceCheckout(amount: number): Observable<{transaction_id: string, customer_id: string}> {
		return this.api.paddleBalanceCheckout(amount);
	}
	
	pssnBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		return this.api.pssnBalanceCheckout(amount);
	}
	
	// strpBalanceCheckout(paymentMethod: string, amount: number): Observable<{client_secret: string}> {
	// 	return this.api.strpBalanceCheckout(paymentMethod, amount);
	// }
	
	strpBalanceCheckout(paymentMethod: string, amount: number): Observable<any> {
		return this.api.strpBalanceCheckout(paymentMethod, amount);
	}
	
	strpBalanceSubscriptionCheckout(paymentMethod: string, amount: number, countLimit: number): Observable<any> {
		return this.api.strpBalanceSubscriptionCheckout(paymentMethod, amount, countLimit);
	}
	
	cancelBalanceSubscription(): Observable<any> {
		return this.api.cancelBalanceSubscription();
	}
	
	// strpBalanceInvoice(paymentMethod: string, amount: number, customerInfo: any): Observable<{payment_link: string}> {
	// 	return this.api.strpBalanceInvoice(paymentMethod, amount, customerInfo);
	// }
	
	fsBalanceCheckout(amount: number): Observable<{secured_payload: string, secured_key: string}> {
		return this.api.fsBalanceCheckout(amount);
	}
	
	fsBalanceSubscriptionCheckout(amount: number, countLimit: number): Observable<any> {
		return this.api.fsBalanceSubscriptionCheckout(amount, countLimit);
	}
	
	getSubUserUsageStats(startDate: string, endDate: string, login: string | null, subusers_pool: string | null): Observable<{stat: {traffic: number, request: number, d_usage: string, d_usage_ts: string }[], total: {traffic: number, requests: number} }> {
		return this.api.getSubUserUsageStats(startDate, endDate, login, subusers_pool);
	}
	
	getUserCertifiedDomains(): Observable<any> {
		return this.api.getUserCertifiedDomains();
	}
	
	addUserDomainCertificate(domain: string, publicKey: string, privateKey: string): Observable<any> {
		return this.api.addUserDomainCertificate(domain, publicKey, privateKey);
	}
	
	removeUserDomainCertificate(domain: string): Observable<any> {
		return this.api.removeUserDomainCertificate(domain);
	}
}
