<div class="di-notification-item" [class.unread]="!notificationItem.read">
	<div class="di-notification-item-header" (click)="readNotification()">
		<div [class]="'di-notification-icon ' + 'di-notification-icon--'+notificationItem.type">
			<mat-icon [svgIcon]="notificationItem.type === 'info' ? 'info_i' : 'exclamation'"></mat-icon>
		</div>
		<div class="di-notification-content" >
			<div class="di-notification-text">
				{{notificationItem.title}}</div>
			<div class="di-notification-time">{{notificationItem.days_ago}}</div>
		</div>
		<div class="di-read-more">
			<mat-icon>chevron_right</mat-icon>
		</div>
	</div>
	<div class="di-notification-expanded mt-1" *ngIf="expand">
		<p class="di-notification-expanded-text" [innerHTML]="notificationItem.message"></p>
		<a *ngIf="notificationItem.link" [href]="notificationItem.link" class="btn btn-default-small btn-notification-link">{{notificationItem.link_text ?? 'View'}}</a>
	</div>
</div>
