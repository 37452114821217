import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ILocationCodeValue} from "../../../core/models/location.model";
import {ISubUser, IUserBalanceSubscription, IUserCustomPrice} from "../../../core/models/user.model";
import {CartItem} from "../../../core/models/cart-item.model";
import {UsageDetails} from "../../../core/models/usage-details.model";

@Injectable()
export class ResellerApi {
	
	constructor(private httpClient: HttpClient) {
	
	}
	getBalanceInfo(): Observable<{balance: number, price_per_traffic: number, custom_price_data: IUserCustomPrice[], subscription: IUserBalanceSubscription}> {
		return this.httpClient.get<{balance: number, price_per_traffic: number, custom_price_data: IUserCustomPrice[], subscription: IUserBalanceSubscription}>('monetizer_api/api/reseller/balance/get', {});
	}
	
	getSubUsersList(options:any): Observable<{data: ISubUser[], totalCount: number}> {
		const params: any = {
		limit: options.take,
		offset: options.skip,
		sort: options.sort ? JSON.stringify(options.sort) :  undefined,
		filter: options.filter ? JSON.stringify(options.filter) :  undefined
		}
		return this.httpClient.post<{data: ISubUser[], totalCount: number}>('monetizer_api/api/reseller/subusers/get', params);
	}
	
	getSubUsersListMath(): Observable<{subusers: ISubUser[]}> {
		return this.httpClient.get<{subusers: ISubUser[]}>('monetizer_api/api/reseller/subusers/get-list', {});
	}
	
	getApiToken(password: string): Observable<{token: string, message: string, success: boolean}> {
		const postData = {
			password
		}
		return this.httpClient.post<{token: string, message: string, success: boolean}>('monetizer_api/api/reseller/generate-api-token', postData);
	}
	
	getLastApiToken(): Observable<{token: string, message: string, success: boolean}> {
		return this.httpClient.get<{token: string, message: string, success: boolean}>('monetizer_api/api/reseller/get-last-api-token');
	}
	
	addSubUserTraffic(subUserId: number, traffic: number): Observable<{message: string, success: boolean}> {
		const postData = {
			subuser_id: subUserId,
			traffic
		}
		return this.httpClient.post<{message: string, success: boolean}>('monetizer_api/api/reseller/subusers/add-traffic', postData);
	}
	
	npBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		const postData = {
			amount
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/reseller/np/add_balance', postData);
	}
	
	cmBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		const postData = {
			amount
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/reseller/cryptomus/add_balance', postData);
	}
	
	paddleBalanceCheckout(amount: number): Observable<{transaction_id: string, customer_id: string}> {
		const postData = {
			amount
		}
		return this.httpClient.post<{transaction_id: string, customer_id: string}>('monetizer_api/api/reseller/paddle/add_balance', postData);
	}
	
	pssnBalanceCheckout(amount: number): Observable<{payment_link: string}> {
		const postData = {
			amount
		}
		return this.httpClient.post<{payment_link: string}>('monetizer_api/api/reseller/payssion/add_balance', postData);
	}
	
	strpBalanceCheckout(payment_method: string, amount: number): Observable<any> {
		const postData = {
			payment_method,
			amount
		}
		return this.httpClient.post<any>('monetizer_api/api/reseller/stripe/add_balance', postData);
	}
	
	strpBalanceSubscriptionCheckout(payment_method: string, amount: number, count_limit: number): Observable<any> {
		const postData = {
			payment_method,
			amount,
			count_limit
		}
		return this.httpClient.post<any>('monetizer_api/api/reseller/stripe/create_subscription', postData);
	}
	
	cancelBalanceSubscription(): Observable<any> {
		return this.httpClient.post<any>('monetizer_api/api/reseller/cancel_subscription', {});
	}
	
	fsBalanceCheckout(amount: number): Observable<{secured_payload: string, secured_key: string}> {
		const postData = {
			amount,
		}
		return this.httpClient.post<{secured_payload: string, secured_key: string}>('monetizer_api/api/reseller/fastspring/add_balance', postData);
	}
	
	fsBalanceSubscriptionCheckout(amount: number, count_limit: number): Observable<any> {
		const postData = {
			amount,
			count_limit
		}
		return this.httpClient.post<any>('monetizer_api/api/reseller/fastspring/create_subscription', postData);
	}
	
	getSubUserUsageStats(startDate: string, endDate: string, login: string | null, subusers_pool: string | null): Observable<{stat: {traffic: number, request: number, d_usage: string, d_usage_ts: string }[], total: {traffic: number, requests: number}}> {
		const postData = {
			start_date: startDate,
			end_date: endDate,
			subuser: login,
			subusers_pool: subusers_pool
		}
		return this.httpClient.post<{stat: {traffic: number, request: number, d_usage: string, d_usage_ts: string }[], total: {traffic: number, requests: number}}>('monetizer_api/api/reseller/subusers/usage-get', postData);
	}
	
	getUserCertifiedDomains() {
		return this.httpClient.get<any>('monetizer_api/api/reseller/certified-domains/get');
	}
	
	addUserDomainCertificate(domain: string, publicKey: string, privateKey: string) {
		const postData = {
			domain: domain,
			public_key: publicKey,
			private_key: privateKey
		}

		return this.httpClient.post<any>('monetizer_api/api/reseller/certified-domains/add', postData);
	}
	
	removeUserDomainCertificate(domain: string) {
		const postData = {
			domain: domain,
		}

		return this.httpClient.post<any>('monetizer_api/api/reseller/certified-domains/delete', postData);
	}
	
	getSubUserUsageDetails(options:any): Observable<{data: UsageDetails[], totalCount: number}> {
		const params: any = {
			startDate: options.startDate,
			endDate: options.endDate,
			subUser: options.subUser,
			limit: options.take,
			offset: options.skip,
			sort: options.sort ? JSON.stringify(options.sort) :  undefined,
			filter: options.filter ? JSON.stringify(options.filter) :  undefined,
		}
		return this.httpClient.post<{data: any, totalCount: number}>('monetizer_api/api/reseller/subusers/usage-details', params);
	}
	
}
