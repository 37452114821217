import {AuthComponent} from "./auth/auth.component";
import {SignInComponent} from "./sign-in/sign-in.component";
import {SignUpComponent} from "./sign-up/sign-up.component";
import {ForgotPassComponent} from "./forgot-pass/forgot-pass.component";
import {ResetPassComponent} from "./reset-pass/reset-pass.component";
import {SignUpNewComponent} from "./sign-up-new/sign-up-new.component";

export const containers = [
  AuthComponent,
  SignInComponent,
  SignUpComponent,
  ForgotPassComponent,
  ResetPassComponent,
  SignUpNewComponent
]

export * from "./auth/auth.component";
export * from "./sign-in/sign-in.component";
export * from "./sign-up/sign-up.component";
export * from "./sign-up-new/sign-up-new.component";
export * from "./forgot-pass/forgot-pass.component";
export * from "./reset-pass/reset-pass.component";
